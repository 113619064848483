// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coating-wax-js": () => import("./../src/pages/coating-wax.js" /* webpackChunkName: "component---src-pages-coating-wax-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-detailing-js": () => import("./../src/pages/detailing.js" /* webpackChunkName: "component---src-pages-detailing-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paint-enhancement-js": () => import("./../src/pages/paint-enhancement.js" /* webpackChunkName: "component---src-pages-paint-enhancement-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

